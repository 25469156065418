/* Password specific styles */

.requirement-icon {
    display: inline-block;
    font-family: "IconsOri";
    font-size: var(--font-size-icon4);
    position: relative;
    top: -4px;
    margin-right: 10px;
    padding-left: 1px;
}

.requirement-icon.requirement-fulfilled::before,
.requirement-icon.requirement-unknown-state::before {
    content: "\e91b";
}

.requirement-icon.requirement-unfulfilled::before {
    content: "\e930";
}

.requirement-fulfilled {
    color: #68BB93;
}

.requirement-unfulfilled {
    color: #C60D22;
}

.requirement-unknown-state {
    color: #999999;
}

.requirement-text {
    font-size: var(--font-size-caption);
    color: #333333;
    position: relative;
    top: -10px;
}

.w-control.password {
    margin-bottom: 10px;
}

.js-new-password > span.field-validation-error {
    display: none;
}